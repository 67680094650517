import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core'
import QrScanner from 'qr-scanner'
import {EMPTY} from 'rxjs'
import {IQrScannerFactory, QR_SCANNER_FACTORY} from '../application/qr-scanner.provider'
import {DataService} from '../services/data.service'
import {BankIDVerificationItem} from 'sparbanken-syd-bil'
import {catchError, filter} from 'rxjs/operators'
import {MatDialog} from '@angular/material/dialog'
import {IdListDialogComponent} from '../common/id-list-dialog/id-list-dialog.component'
import {CameraDevice, Html5Qrcode} from 'html5-qrcode'

export class FakeScanner {
  constructor(private cb: (r: any) => void) {
  }

  start(): Promise<any> {
    this.cb({})
    return Promise.resolve({})
  }

  hasFlash(): Promise<any> {
    return Promise.resolve({})
  }

  stop(): void {
    /**
     * Intentionally left blank
     */
  }

  toggleFlash(): Promise<any> {
    return Promise.resolve()
  }
}


export class QrFactory implements IQrScannerFactory {
  public getScanner(element: ElementRef, callback: (r: any) => void, options: any): QrScanner {
    return new QrScanner(element as unknown as HTMLVideoElement, callback, options)
  }
}

export class FakeQrFactory implements IQrScannerFactory {
  private options: any

  public getScanner(element: ElementRef, callback: (r: any) => void, options: any): QrScanner {
    this.options = options
    return new FakeScanner(callback) as unknown as QrScanner
  }
}

@Component({
  selector: 'spb-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements AfterViewInit {
  @Output() handleSwipeLeft = new EventEmitter<Event>()
  @Output() handleSwipeRight = new EventEmitter<Event>()
  @ViewChild('majFuckingBastards', {static: true}) videoTest: ElementRef = new ElementRef(undefined)

  public haveFlash = false

  private qrScanner: QrScanner | undefined

  private html5QrCode: Html5Qrcode | undefined

  constructor(
    @Inject(QR_SCANNER_FACTORY) private qrFactory: IQrScannerFactory,
    private dataService: DataService,
    private matDialog: MatDialog,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) {
  }

  public ngAfterViewInit() {
    this.html5QrCode = new Html5Qrcode(/* element id */ 'majFuckingBastards');
  }

  public start(): void {
    if (this.qrScanner) {
      this.qrScanner.start().then(() => {
        this.checkFlash()
      })
    }
  }

  public stop(): void {
    if (this.qrScanner) {
      this.qrScanner.stop()
    }
  }

  public flash() {
    if (this.qrScanner) {
      this.qrScanner.toggleFlash().then()
    }
  }

  private identify(scanResult: string): void {
    this.dataService.checkId(scanResult).pipe(
      catchError(() => {
        this.openDialog()
        return EMPTY
      }),
      filter(Boolean)
    ).subscribe({
      next: (res: BankIDVerificationItem): void => {
        if ('user' in res) {
          this.dataService.userData.set({...res, title: 'Legitimerad'})
        } else {
          this.dataService.userData.set({...res, title: 'Ej legitimerad'})
        }
        this.dataService.bankIDVerificationItem.update((oldBid: BankIDVerificationItem[]) => [res, ...oldBid])
        this.openDialog()
      }
    })
  }

  private openDialog(): void {
    this.ngZone.run(() => {
      this.matDialog.open(IdListDialogComponent, {
        width: '300px'
      }).afterClosed().subscribe({
        next: () => this.testStart()
      })
    })
  }

  private checkFlash(): void {
    if (this.qrScanner) {
      this.qrScanner.hasFlash().then((haveFlash: boolean) => this.haveFlash = haveFlash)
    }
  }

  private camera = ''
  private getCameras(): void {
    Html5Qrcode.getCameras().then((devices: CameraDevice[]) => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
        this.camera = devices[0].id
        console.log(this.camera)
        // .. use this to start scanning.
      }
    }).catch(err => {
      // handle err
    })
  }



  public testStart(): void {
    this.getCameras()
    console.log(this.camera)
    if(this.camera !== '') {
      this.html5QrCode?.start(
        this.camera,
        {
          fps: 10,    // Optional, frame per seconds for qr code scanning
        },
        (decodedText, decodedResult) => {
          // do something when code is read
          console.log(decodedText)
          console.log(decodedResult)
          this.html5QrCode?.stop().then()
          this.identify(decodedText)
        },
        (errorMessage) => {
          // parse error, ignore it.
        })
        .catch((err) => {
          // Start failed, handle it.
        })
    }

  }

}
