<div class="holder"
     (swiperight)="handleSwipeRight.emit()"
>
  <h2>Hjälp & Användarguide</h2>
  <p>Välkommen till användarguiden för vår legitimeringsapp. Här följer en snabb genomgång av appens funktioner som
    finns under våra fyra huvudflikar.</p>

  <h3>Skanna</h3>
  <p>Under fliken "Skanna" kan du skanna kunders ID-kort via BankID. Tryck bara på knappen och börja skanna. Appen
    kommer därefter att berätta om det skannade ID-kortet är giltigt eller inte.</p>

  <h3>Utförda</h3>
  <p>Här kan du se en lista över dina tidigare skanningar. Varje post visar om skanningen var lyckad eller misslyckad,
    samt datum och tid för skanningen.</p>
  <p>För att se mer detaljer om en specifik skanning, klicka bara på posten i listan. Detta tar dig till en detaljsida
    där du kan se mer information om den utförda skanningen.</p>

  <p>Vi hoppas att denna guide ger dig en bra start med appen. Om du har ytterligare frågor eller behöver mer hjälp,
    tveka inte att kontakta PP&IT.</p>
  <p>Lycka till med dina skanningar och ha en trevlig arbetsdag!</p>
</div>
