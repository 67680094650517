<div class="holder">
  <div class="text-container  mat-elevation-z8 ">
    <p>Välkommen till BankID Legitimation. För att använda tjänsten behöver du logga in med BankID.</p>
    <spb-bankid [bankIdUrl]="bankIdUrl"
                (accessToken)="configService.setToken($event)"
                [domain]="domain"
    ></spb-bankid>
  </div>
</div>
<div class="spb-filler"></div>
