import {Component, Injectable, OnInit, ViewChild} from '@angular/core'
import {BankIDVerificationItem} from 'sparbanken-syd-bil'
import {DataService} from '../services/data.service'
import * as hammer from 'hammerjs'
import {HammerGestureConfig} from '@angular/platform-browser'

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    'pinch': {enable: false},
    'rotate': {enable: false}
  }

  load(): Promise<any> {
    return Promise.resolve(hammer)
  }
}

@Component({
  selector: 'spb-start',
  templateUrl: './start.component.html'
})
export class StartComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: any

  constructor(private dataService: DataService) {
  }

  public ngOnInit() {
    /**
     * Get all verifications (succeeded and errors) from database and set them to a signals list
     */
    this.dataService.getMyTransactions().subscribe({
      next: (bid: BankIDVerificationItem[]) => this.dataService.bankIDVerificationItem.set(bid)
    })
  }

  public swipeLeft() {
    this.tabGroup.selectedIndex += 1 // Switch tab to the right
  }

  public swipeRight() {
    this.tabGroup.selectedIndex -= 1 // Switch tab to the left
  }
}
