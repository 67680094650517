<div
  (swipeleft)="handleSwipeLeft.emit()"
  (swiperight)="handleSwipeRight.emit()"
  class="list-holder">
  <div class="filter-holder">
    <mat-form-field>
      <mat-label>Sök</mat-label>
      <label>
        <input [formControl]="filterControl" matInput name="filterTrans">
      </label>
    </mat-form-field>
  </div>

  <div>
    <table [dataSource]="dataSource" class="mat-elevation-z8" mat-table matSort
           matSortActive="identifiedTimeStamp" matSortDirection="desc">
      <caption>Lista över legitimeringar</caption>

      <ng-container matColumnDef="timeStamp">
        <th *matHeaderCellDef id="timeStamp" mat-header-cell
            mat-sort-header="timeStamp"> Datum
        </th>
        <td (click)="openDialog(element)" *matCellDef="let element" mat-cell>
          {{element.timeStamp | date: 'YY-MM-dd': undefined : 'fr'}} </td>
      </ng-container>

      <ng-container matColumnDef="personalNumber">
        <th *matHeaderCellDef id="personalNumber" mat-header-cell mat-sort-header="personalNumber"> Personnummer
        </th>
        <td (click)="openDialog(element)" *matCellDef="let element" mat-cell>
          <ng-container *ngIf="element.user">
            {{element.user.personalNumber | slice:0:8 }}-{{element.user.personalNumber | slice:8 }}
          </ng-container>
          <ng-container *ngIf="!element.user">
            Misslyckad
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef id="name" mat-header-cell mat-sort-header="name"> Namn
        </th>
        <td (click)="openDialog(element)" *matCellDef="let element" mat-cell>
          {{element.user && element.user.name}}
        </td>
      </ng-container>

      <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
      <tr *matRowDef="let element; columns: columnsToDisplay" mat-row></tr>

      <tr *matNoDataRow class="no-data">
        <td [attr.colspan]="columnsToDisplay.length" class="no-data">
          Inga legitimeringar hittades.
        </td>
      </tr>
    </table>
  </div>
</div>
