<div>
  <div class="holder">
    <div class="middle">
      <div class="logo">
        ID App
      </div>
      <div *ngIf="ready" class="middle">
        <div class="spb-filler"></div>
        <span *ngIf="user">{{user.name}}</span>
        <div *ngIf="configService.configState$ | async as config" class="middle-menu">
          <div *ngIf="config.ready">
            <button [matMenuTriggerFor]="menu" mat-button>
              <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="logout()" mat-menu-item>Logga ut</button>
              <button (click)="install()" mat-menu-item>Installera</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
