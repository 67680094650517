import {Inject, Injectable} from '@angular/core'
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {HelperService} from "@sparbanken-syd/sparbanken-syd-bankid"
import {LOCAL_STORAGE} from "./local-storage.provider"

const ACCESS_TOKEN_NAME = 'id-token'

/**
 * Inject to set auth header on all requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * @param injectedLocalStorage
   */
  constructor(@Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  /**
   * Intercept all requests and add auth token. We should however
   * do this only on white listed requests so that we do not
   * send our tokens when not needed.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = HelperService.ValidateToken(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))

    const headers = req.headers

    /**
     * Only send authorization bearer if endpoint is relevant
     */
    if (req.url.indexOf('user') !== -1) {
      const authReq = req.clone({
        headers: headers.set('Authorization', `Bearer ${token}`)
      })
      return next.handle(authReq)
    }

    /**
     * Else set no more headers
     */
    return next.handle(req)
  }
}
