import { Component } from '@angular/core'
import version from '../assets/package.json'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public version = version.version
}
