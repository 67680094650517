import { Component } from '@angular/core'

@Component({
  selector: 'spb-install-instructions-dialog',
  templateUrl: './install-instructions-dialog.component.html',
  styleUrls: ['./install-instructions-dialog.component.scss']
})
export class InstallInstructionsDialogComponent {

}
