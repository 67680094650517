<div class="holder" (swipeleft)="handleSwipeLeft.emit()" (swiperight)="handleSwipeRight.emit()" style="min-height: 50vh">
  <h3>Välkommen till Sparbanken Syds legitimeringsverktyg!</h3>
  <div class="text-holder">
    <p>Här kan du enkelt skanna kunders legitimeringskort från BankID och få en överblick över dina tidigare utförda legitimeringar.</p>

    <p>Appen är ett hjälpmedel för att vi tillsammans ska säkerställa en hög säkerhet och kundservice. Vi strävar efter att göra ditt arbete enklare och mer effektivt.</p>

    <p>Tack för att du är en viktig del av Sparbanken Syd. Vi hoppas att du kommer tycka om att använda denna app!</p>

    <p>Lycka till och ha en fantastisk arbetsdag!</p>
  </div>
</div>
